import C from "./Dashboard.module.css";

import { Link as RouterLink } from "@tanstack/react-router";
import { useAtomValue } from "jotai/react";
import { type Settings } from "react-slick";
import { WButton } from "../../components/button/WButton.tsx";
import { WCarousel } from "../../components/carousel/WCarousel.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { cn } from "../../utils/classNames.ts";
import { getResourceCreateLink } from "../api/resourceTypeLinks.ts";
import {
    getResourceDescription,
    getResourceIconClass,
    getResourceParent,
    getResourceTypeName,
    resourceTypeEnabledAtom,
    type ResourceType,
} from "../api/resourceTypes.tsx";
import { NextArrow } from "./NextArrow.tsx";
import { PrevArrow } from "./PrevArrow.tsx";

export function CoreServices({ more }: { more: boolean }) {
    //#region Hooks
    const canCreate = useAtomValue(resourceTypeEnabledAtom);
    //#endregion

    const createOptions = (
        [
            "virtual_machine",
            "bucket",
            "ip_address",
            "vpc",
            "load_balancer",
            "access_delegation",
            "api_token",
        ] satisfies ResourceType[]
    ).filter((type) => canCreate(type));

    const settings: Settings = {
        dots: true,
        infinite: false,
        speed: 500,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        customPaging: () => <div className="dot" />,
    };

    const createLinks = createOptions.map((type: ResourceType) => <CreateLink key={type} type={type} more={more} />);

    return (
        <div className={cn(C.CreateLinks, !more && C.grid)}>
            {more ? (
                <WCarousel slideSettings={settings} slideWidth={260} slideClass={C.CreateSlider} arrowsHidden={3}>
                    {createLinks}
                </WCarousel>
            ) : (
                createLinks
            )}
        </div>
    );
}

function CreateLink({ type, more }: { type: ResourceType; more?: boolean }) {
    const linkProps = getResourceCreateLink(type);
    if (!linkProps) {
        return null;
    }

    const icon = (
        <div className={C.Icon}>
            <MaskIcon className={cn(getResourceIconClass(type), C.Mask)} />
        </div>
    );
    const title = <div className={C.Title}>{getResourceTypeName(type)}</div>;
    const parent = <div className={cn(C.Module, "color-muted", "font-size-small")}>{getResourceParent(type)}</div>;

    return more ? (
        <div className={cn(C.CreateLink, C.wide)}>
            {icon}
            <div className={C.Content}>
                {parent}
                {title}
                <div className={cn(C.Description, "color-muted", "font-size-small")}>
                    {getResourceDescription(type)}
                </div>
            </div>

            <WButton
                color="primary"
                icon="jp-arrow-thin-right-icon"
                iconSide="right"
                variant="ghost"
                size="bar"
                action={linkProps}
            >
                Create New {getResourceTypeName(type)}
            </WButton>
        </div>
    ) : (
        <RouterLink {...linkProps}>
            <div className={cn(C.CreateLink, C.Hoverable)}>
                {icon}
                <div className={C.Content}>
                    {title}
                    {parent}
                </div>

                <div className={C.Btn}>
                    <MaskIcon className="jp-arrow-thin-right-icon size-0.75rem" />
                </div>
            </div>
        </RouterLink>
    );
}
