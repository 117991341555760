import { atom } from "jotai/vanilla";
import { mergeQueries } from "../../utils/query/mergeQueries.ts";
import { accessDelegationListQueryAtom, type AccessDelegationWithType } from "../access/delegation/apiOperations.ts";
import type { AccessImpersonationWithType } from "../access/impersonation/apiOperations.ts";
import { apiTokenListQueryAtom, type ApiTokenWithType } from "../access/token/apiOperations.ts";
import { allVmQueryAtom, type VirtualMachineLoc } from "../compute/vmQuery.ts";
import { allIpQueryAtom, type IpAddressWithType } from "../network/ipAddress/apiOperations.ts";
import { allLbsQueryAtom, type LoadBalancerWithType } from "../network/loadbalancer/apiOperations.ts";
import { allVpcsQueryAtom, type VpcWithType } from "../network/vpc/apiOperations.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "../services/servicesQuery.ts";
import type { StorageWithType } from "../storage/objectStorage/apiOperations.ts";
import { storageListQueryAtom } from "../storage/storageListQuery.ts";

export type RecentWithType =
    | ApiTokenWithType
    | AccessDelegationWithType
    | IpAddressWithType
    | LoadBalancerWithType
    | VpcWithType
    | VirtualMachineLoc
    | ManagedServiceLoc
    | StorageWithType
    | AccessImpersonationWithType;

export const allResourcesAtom = atom((get) => {
    const vms = get(allVmQueryAtom);

    const services = get(allServicesQueryAtom);

    const buckets = get(storageListQueryAtom);

    const vpcs = get(allVpcsQueryAtom);
    const ips = get(allIpQueryAtom);
    const lbs = get(allLbsQueryAtom);

    const token = get(apiTokenListQueryAtom);
    const access = get(accessDelegationListQueryAtom);

    return mergeQueries({ vms, services, vpcs, ips, lbs, buckets, token, access }, (maps) =>
        maps.flatMap((m: Map<unknown, RecentWithType>) => [...m.values()]),
    );
});

const resourceQueriesAtom = atom((get) => {
    const vms = get(allVmQueryAtom);

    const services = get(allServicesQueryAtom);

    const buckets = get(storageListQueryAtom);

    const vpcs = get(allVpcsQueryAtom);
    const ips = get(allIpQueryAtom);
    const lbs = get(allLbsQueryAtom);

    return [vms, services, vpcs, ips, lbs, buckets].map((q) => q.data);
});

// Only export a boolean instead of a query to prevent unnecessary re-renders (an updating `QueryResult` keeping
// the same `data` will still always cause a re-render, because it is an object and Jotai uses `Object.is` for
// comparison).
export const hasAnyResourcesAtom = atom((get) => get(resourceQueriesAtom).some((m) => m && m.size > 0));

export const hasNoResourcesAtom = atom((get) => get(resourceQueriesAtom).every((m) => m && m.size === 0));
