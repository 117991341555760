import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Slider.css";

import { Children, type ReactNode, useRef, useState } from "react";
import Slider, { type Settings } from "react-slick";
import { cn } from "../../utils/classNames.ts";
import { useLayoutSizeObserver } from "../../utils/useLayoutSizeObserver.ts";

export function WCarousel({
    children,
    slideSettings,
    slideWidth,
    slideClass,
    arrowsHidden,
}: {
    children: ReactNode;
    slideSettings: Settings;
    slideWidth: number;
    slideClass?: string;
    arrowsHidden?: number;
}) {
    //#region Hooks
    const [perPage, setPerPage] = useState(4);
    const ref = useRef<HTMLDivElement>(null);
    //#endregion

    useLayoutSizeObserver({
        ref,
        onResize({ width }) {
            const perPage = Math.max(1, Math.floor(width / slideWidth));
            setPerPage(perPage);
        },
    });

    const settings: Settings = {
        slidesToShow: perPage,
        slidesToScroll: Children.count(children) < perPage ? 1 : perPage,
        arrows: arrowsHidden ? perPage >= arrowsHidden : true,
        ...slideSettings,
    };

    console.log(settings.dots);
    return (
        <div ref={ref}>
            <Slider className={cn(slideClass, settings.dots && "slick-with-dots")} {...settings}>
                {children}
            </Slider>
        </div>
    );
}
